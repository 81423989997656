<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import { getNode } from '@formkit/core'
import type { LoginFormProps } from './LoginForm.props'

const { getRecaptchaToken, verifyReCaptchaToken } = useRecaptchaSite()
const { privacyPolicy } = useRouteHelper()
const { ts } = useI18n()
const {
  LOGIN_FORM_ID,
  LOGIN_FORM_ID_SIGNUP_PAGE,
  loginEmail,
  emailExistOnAuth0,
  loginEmailIsValid,
  socialLoginConnection,
  loginError,
  loginCheckEmail,
  loginCleanCredentials,
  login,
} = useLogin()
withDefaults(defineProps<LoginFormProps>(), {
  signupPage: false,
})

const { cleanRegistrationData } = useRegistration()

const { openDialog } = useDialog()
const currentEmail = ref('')
const formData = ref({
  email_login_sideslide: loginEmail.value,
  password_login_sideslide: '',
})

const recaptchaErrorMsg = ref<string>('')

// handling login and its errors
const handleLogin = async () => {
  if (!emailExistOnAuth0.value) {
    //if email doesn't exist, just check it
    checkEmail()
    return
  }

  const token = await getRecaptchaToken('login')
  if (!token) {
    recaptchaErrorMsg.value = 'generic.recaptchaError'
    return
  }
  const verifyToken = await verifyReCaptchaToken(token)
  if (!(verifyToken.data.value as any).response.success) {
    recaptchaErrorMsg.value = 'generic.recaptchaError'
    return
  }

  await login(
    formData.value.email_login_sideslide,
    formData.value.password_login_sideslide
  )
}

watch(loginError, newVal => {
  if (newVal) {
    getNode('password-login')?.setErrors(
      ts('myAccount.login.wrongEmailOrPassword')
    )
  }
})

const onFormUpdate = useDebounceFn(
  (
    { email_login_sideslide: newEmail, password_login_sideslide: newPw },
    { email_login_sideslide: oldEmail, password_login_sideslide: oldPw }
  ) => {
    if (oldEmail !== newEmail) {
      loginEmail.value = newEmail
      if (currentEmail.value !== newEmail) {
        emailExistOnAuth0.value = false
        cleanRegistrationData()
      }
    }
  },
  100
)
watch(formData, onFormUpdate, { deep: true })
const checkEmail = async () => {
  currentEmail.value = formData.value.email_login_sideslide
  await loginCheckEmail(formData?.value?.email_login_sideslide)
}

const emit = defineEmits<{
  (e: 'email', value: string): void
  (e: 'exist', value: boolean): void
}>()

const checkEmailValid = useDebounceFn(async (validState: boolean) => {
  currentEmail.value = formData.value.email_login_sideslide
  loginEmailIsValid.value = validState
  emit('email', formData.value.email_login_sideslide)
}, 100)

const handleOpenDialog = () => {
  openDialog(SIDEBAR_IDS.loginResetPassword)
}
</script>
<template>
  <div class="gap-xl flex flex-col">
    
<FormKitLazyProvider config-file="true">
<div class="flex flex-col gap-6">
      <h3 v-if="!signupPage" class="text-medium-5">
        {{
          ts(
            `myAccount.login.${emailExistOnAuth0 ? 'welcomeBack' : 'insertEmail'}`
          )
        }}
      </h3>
      <div class="text-light-6 flex flex-col">
        <FormKit
          :id="`${signupPage ? LOGIN_FORM_ID_SIGNUP_PAGE : LOGIN_FORM_ID}`"
          ref="formLoginRef"
          v-model="formData"
          type="form"
          :incomplete-message="false"
          :classes="{
            actions: 'hidden',
          }"
          @submit="handleLogin"
          @submit-invalid="scrollToErrors"
        >
          <InputsTextFields
            type="email"
            :label="ts('myAccount.login.email')"
            autocomplete="email"
            name="email_login_sideslide"
            :classes="{ outer: 'mb-6' }"
            validation="required|email"
            :validation-messages="{
              required: $ts('generic.requiredEmail'),
              email: $ts('generic.invalidEmail'),
            }"
            validation-visibility="submit"
            :show-remove-button="emailExistOnAuth0"
            show-confirm-button
            @confirm="checkEmail"
            @remove="loginCleanCredentials"
            @valid="checkEmailValid($event)"
          />
          <Transition
            v-show="emailExistOnAuth0"
            appear
            enter-active-class="transition-all duration-500 overflow-hidden"
            enter-from-class=" -translate-y-full opacity-20"
            enter-to-class=" translate-y-0 opacity-100"
            leave-active-class="transition-all duration-500 overflow-hidden"
            leave-from-class=" translate-y-0 opacity-100"
            leave-to-class=" -translate-y-1/2 opacity-20"
          >
            <div v-if="socialLoginConnection">
              <OrganismsMessageLong
                :message="`${ts('myAccount.login.emailExist')}<br/><span>${ts('myAccount.login.socialLogin')}</span>`"
                class="[&>p]:text-light-7 mb-xl"
              />
              <LoginSocialList
                :socials="[socialLoginConnection]"
                :width="signupPage ? 'medium' : 'full'"
              />
            </div>
            <div v-else>
              <OrganismsMessageLong
                v-if="signupPage"
                :message="`${ts('myAccount.login.emailRegistered')} <span>${ts('myAccount.login.passwordLoginOrGuest')}</span>`"
              />
              <OrganismsMessageLong
                v-if="recaptchaErrorMsg"
                :message="`${ts('myAccount.login.emailRegistered')} <span>${recaptchaErrorMsg}</span>`"
              />
              <InputsPassword
                id="password-login"
                :label="ts('myAccount.login.password')"
                name="password_login_sideslide"
                validation="required"
                validation-visibility="submit"
                :validation-messages="{
                  required: ts('myAccount.login.passwordRequired'),
                }"
                :show-confirm-button="false"
                :disabled="!emailExistOnAuth0"
                :classes="{
                  outer: 'mb-6',
                  suffixIcon: '!gap-0',
                }"
                autocomplete
                :action-text="ts('myAccount.login.forgotPassword')"
                :is-action-text-opening-dialog="true"
                @click-action-button="handleOpenDialog"
              />
            </div>
          </Transition>
        </FormKit>
      </div>
    </div>
    <!-- <LoginSocialList
      v-if="!emailExistOnAuth0"
      :width="signupPage ? 'medium' : 'full'"
    >
      <template #title>
        {{ ts('myAccount.login.loginWith') }}
      </template>
</LoginSocialList> -->
    <slot name="cta" />
    <MoleculesLinkWrapper
      anatomy="link"
      :aria-label="$ts('myAccount.login.privacy')"
      :icon-show-default="false"
      class="self-center"
      :to="privacyPolicy"
    >
      <template #label>
        {{ $ts('myAccount.login.privacy') }}
      </template>
    </MoleculesLinkWrapper>
</FormKitLazyProvider>

  </div>
</template>

<style scoped lang="scss">
input[type='text'] {
  /* enlarge by 16/12 = 133.33% */
  border-radius: 6.666666667px;
  font-size: 16px;
  line-height: 26.666666667px;
  padding: 6.666666667px;
  width: 133.333333333%;

  /* scale down by 12/16 = 75% */
  transform: scale(0.75);
  transform-origin: left top;

  /* remove extra white space */
  margin-bottom: -10px;
  margin-right: -33.333333333%;
}
</style>
