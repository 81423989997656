<script lang="ts" setup>
import type { LoginRegistrationSuccessfulProps } from './LoginRegistrationSuccessful.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { dispatchAccountRegistrationEvent } = useGAAccountEvents()
const { isRegistration } = useLogin()
const { cleanRegistrationData, registrationSuccessful } = useRegistration()
const { closeDialog } = useDialog()
const { myAccount, orders, getCanonicalUrl } = useRouteHelper()

defineProps<LoginRegistrationSuccessfulProps>()

const exitRegistration = () => {
  cleanRegistrationData()
  isRegistration.value = false
  registrationSuccessful.value = false
  closeDialog(SIDEBAR_IDS.loginSidebar)
}

const handleGoToAccount = () => {
  exitRegistration()
  const path = getCanonicalUrl(myAccount)
  navigateTo(path)
}

const handleGoToOrders = () => {
  exitRegistration()
  const path = getCanonicalUrl(orders)
  navigateTo(path)
}
dispatchAccountRegistrationEvent()
</script>

<template>
  <div class="gap-lg flex flex-col md:gap-10">
    <div class="gap-xs flex flex-col">
      <span class="text-medium-4">
        {{ $ts('myAccount.registration.success.title') }}
      </span>
      <span class="text-medium-6">
        {{ $ts('myAccount.registration.success.subtitle') }}
      </span>
    </div>
    <div v-if="isThankYouPage" class="gap-sm flex flex-col">
      <AtomsCta :full-width="true" @click-handler="handleGoToAccount">
        <template #label>
          {{ $ts('myAccount.logged.goToYourAccount') }}
        </template>
      </AtomsCta>
      <AtomsCta
        anatomy="secondary"
        :full-width="true"
        @click-handler="handleGoToOrders"
      >
        <template #label>
          {{ $ts('checkoutPage.thankYou.reviewOrder') }}
        </template>
      </AtomsCta>
    </div>
    <AtomsCta v-else :full-width="true" @click-handler="exitRegistration">
      <template #label>
        {{ $ts('myAccount.registration.success.cta') }}
      </template>
    </AtomsCta>
  </div>
</template>
