<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { countryPrefixes } from '@design-system/data/countryPrefixes'
import { getNode } from '@formkit/core'
import type { LoginRegistrationProps } from '@design-system/components/Login/Registration/LoginRegistration.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { ts } = useI18n()
const { getRecaptchaToken, verifyReCaptchaToken } = await useRecaptchaSite()

const {
  registrationSuccessful,
  REGISTRATION_FORM_PERSONAL_INFO_ID,
  cleanRegistrationData,
  registrationUser,
  registerUser,
  brandPreference,
  isOtpValid,
} = useRegistration()

const props = defineProps<LoginRegistrationProps>()

const emit = defineEmits<{
  (e: 'email', value: string): void
}>()

const { checkoutUrl, cartUrl, signupPage } = useRouteHelper()
const route = useRoute()
const { login, emailExistOnAuth0, emailVerified, loginEmailIsValid } =
  useLogin()
const isSignupPage = (route.path as string).includes(signupPage)

const isCheckoutPage = computed(
  () => route.path.includes(checkoutUrl) || props.isThankYouPage
)

const isCartPage = computed(() => route.path.includes(cartUrl))

const emailExistOnSF = useState('EMAIL_EXIST_0N_AUTH0_SALESFORCE')
const { isDialogOpen } = useDialog(SIDEBAR_IDS.loginSidebar)

const showRegistrationForm = computed(() => {
  if (isCheckoutPage.value || isCartPage.value) {
    return true
  }
  if (!emailExistOnAuth0.value && !emailVerified.value) {
    if (!isCheckoutPage.value && !isCartPage.value && !emailExistOnSF.value) {
      return true
    }
    return isOtpValid.value
  } else if (!emailExistOnSF.value) {
    if (!isCheckoutPage.value && !isCartPage.value) {
      return true
    }
    return isOtpValid.value
  }
  return false
})

const { country } = useRouteHelper()

const currentPrefix = computed(() => {
  const currentPrefix = countryPrefixes.find(
    el => el.code === country.toUpperCase()
  )
  return `${currentPrefix?.code} ${currentPrefix?.dial_code}`
})

watch(
  [currentPrefix, brandPreference],
  () => {
    registrationUser.value.prefix = currentPrefix.value ?? ''
    registrationUser.value.brand_preferences = brandPreference.value
  },
  {
    immediate: true,
  }
)

const scrollToErrorElement = (element: string) => {
  const error = document.getElementById(element)
  if (error) {
    error.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}

const recaptchaErrorMsg = ref<string>('')

const checkEmailValid = async (validState: boolean) => {
  loginEmailIsValid.value = validState
  emit('email', registrationUser.value.email)
}

const submitCurrentForm = async () => {
  // handle submission logic here
  // if not in cart or checkout user to whom the register form is shown need to put in the otp be4 submitting
  if (!isCartPage.value && !isCheckoutPage.value) {
    if (!isOtpValid.value) {
      const errorMessage = getNode('verificationCode')?.value
        ? ts('myAccount.registration.email.wrongCode')
        : ts('myAccount.registration.email.codeRequired')

      getNode('verificationCode')?.setErrors('verificationCode', errorMessage)
      scrollToErrorElement('verificationCode')
      return
    }
  }

  try {
    const token = await getRecaptchaToken('signup')
    if (!token) {
      recaptchaErrorMsg.value = 'generic.recaptchaError'
      return
    }
    const verifyToken = await verifyReCaptchaToken(token)
    if (!(verifyToken.data.value as any).response.success) {
      recaptchaErrorMsg.value = 'generic.recaptchaError'
      return
    }
    await registerUser()
    //third parameter that is only needed when a user registers
    login(
      registrationUser.value.email,
      registrationUser.value.password,
      isSignupPage
    )
    cleanRegistrationData()
  } catch (error) {
    getNode('loginRegistrationEmail')?.setErrors(
      ts('myAccount.registration.error')
    )
    scrollToErrorElement('loginRegistrationEmail')
    console.error(error)
  }
}
</script>

<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<FormKit
      v-if="!registrationSuccessful"
      :id="REGISTRATION_FORM_PERSONAL_INFO_ID"
      v-model="registrationUser"
      type="form"
      :incomplete-message="false"
      :submit-attrs="{ outerClass: 'hidden' }"
      @submit="submitCurrentForm"
      @submit-invalid="scrollToErrors"
    >
      <div class="gap-lg md:gap-xl pb-md flex flex-col">
        <LoginRegistrationEmail
          :is-checkout-page="isCheckoutPage"
          :is-thank-you-page="isThankYouPage"
          :is-cart-page="isCartPage"
          @valid="checkEmailValid"
        />

        <div
          v-if="emailExistOnSF && isOtpValid && !emailExistOnAuth0"
          class="text-medium-6"
        >
          {{ ts('myAccount.login.firstLogin') }}
        </div>
        <div v-else class="text-medium-6">
          {{ $ts('myAccount.registration.registrationInfo') }}
        </div>

        <LoginRegistrationForm v-if="showRegistrationForm">
          <template #cta>
            <div class="w-full">
              <div class="print:hidden">
                <SignupInPageSubmitButton
                  v-if="
                    isOtpValid &&
                    !registrationSuccessful &&
                    isSignupPage &&
                    !isDialogOpen
                  "
                  :form-id="REGISTRATION_FORM_PERSONAL_INFO_ID"
                  :is-otp-valid="isOtpValid"
                  :is-registration="true"
                />
              </div>
            </div>
          </template>
        </LoginRegistrationForm>

        <OrganismsMessageLong
          v-if="recaptchaErrorMsg"
          :message="`${$ts('myAccount.login.emailRegistered')} <span class='text-book-7'>${recaptchaErrorMsg}</span>`"
        />
      </div>
    </FormKit>
    <LoginRegistrationSuccessful v-else :is-thank-you-page="isThankYouPage" />
</FormKitLazyProvider>

  </div>
</template>
