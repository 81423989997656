<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { LoginRegistrationEmailProps } from './LoginRegistrationEmail.props'
import { getNode, type FormKitNode } from '@formkit/core'

const props = defineProps<LoginRegistrationEmailProps>()
const emit = defineEmits<{
  (e: 'valid', value: boolean): void
}>()

const { ts } = useI18n()
const { REGISTRATION_FORM_PERSONAL_INFO_ID, registrationUser, isOtpValid } =
  useRegistration()
const { isRegistration, loginEmail } = useLogin()

const config = useAppConfig()
const currentLanguage = config.currentLanguage

const { closeDialog } = useDialog()

const node = ref<FormKitNode>(getNode(REGISTRATION_FORM_PERSONAL_INFO_ID)!)

// CONSTANTS
const CODE_REQUEST_TIMER = 30 //seconds
const CODE_VALIDITY_TIMER = 600 // 10 min in seconds

// TIMER
type Timer = {
  id?: NodeJS.Timeout
  active: boolean
  time: number
  timeLeft: number
}

const codeRequest = ref<Timer>({
  active: false,
  time: CODE_REQUEST_TIMER,
  timeLeft: CODE_REQUEST_TIMER,
})

const codeValidity = ref<Timer>({
  active: false,
  time: CODE_VALIDITY_TIMER,
  timeLeft: CODE_VALIDITY_TIMER,
})

const startTimer = (timer: Ref<Timer>) => {
  stopTimer(timer)
  timer.value.active = true
  timer.value.timeLeft = timer.value.time

  timer.value.id = setInterval(() => {
    timer.value.timeLeft--

    if (timer.value.timeLeft <= 0) {
      clearInterval(timer.value.id ?? 0)
      timer.value.active = false
    }
  }, 1000)
}

const stopTimer = (timer: Ref<Timer>) => {
  clearInterval(timer.value.id)
  timer.value.active = false
}

// EDIT MAIL
const editEmailHandler = () => {
  isOtpValid.value = false
  getNode('verificationCode')?.reset()
  node?.value.input({})
  isRegistration.value = false
  if (props.isCheckoutPage) {
    closeDialog(SIDEBAR_IDS.loginSidebar)
  }
}

//SUBMIT MAIL
const sendCodeRequest = async () => {
  if (codeValidity.value.active) {
    stopTimer(codeValidity)
  }
  startTimer(codeRequest)
  startTimer(codeValidity)
  try {
    await $fetch('/api/auth0/preRegistrationCheck', {
      method: 'POST',
      body: {
        email: loginEmail.value,
        notp: props.isCheckoutPage || props.isCartPage,
        language: currentLanguage,
      },
    })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

// for HMR fix
const wasSent = useState('code_on_mount_sent', () => false)
onMounted(async () => {
  if (!wasSent.value) {
    if (!props.isCheckoutPage && !props.isCartPage) {
      wasSent.value = await sendCodeRequest()
    }
  }
})

// validate otp

const validateOtp = async () => {
  const isValid = await $fetch<boolean>('/api/auth0/validateOTP', {
    method: 'POST',
    body: {
      email: registrationUser.value.email,
      otp: registrationUser.value.verificationCode,
    },
  })
  isOtpValid.value = isValid
}

const stopWatching = watch(registrationUser, async (v, oldV) => {
  if (!v || !oldV) return

  const newOtp = v.verificationCode
  const oldOtp = oldV.verificationCode

  if (newOtp.length === 6 && newOtp !== oldOtp) {
    await validateOtp()
    if (!isOtpValid.value) {
      getNode('verificationCode')?.setErrors(
        'verificationCode',
        ts('myAccount.registration.email.wrongCode')
      )
    }
  }
})

watch(isOtpValid, isValid => {
  if (isValid) stopWatching()
})
const emailExistOnSF = useState('EMAIL_EXIST_0N_AUTH0_SALESFORCE')

onUnmounted(() => {
  getNode('verificationCode')?.clearErrors()
  getNode('verificationCode')?.reset()
  wasSent.value = false
  // Clean user exist on SALESFORCE state
  emailExistOnSF.value = false
})

const shouldShowVerificationCodeField = computed(() => {
  return !props.isCartPage && !props.isCheckoutPage
})

const helpText = computed(() => {
  if (!props.isCartPage && codeValidity.value.active) {
    return ts('myAccount.registration.email.codeSent')
  } else if (!codeValidity.value.active) {
    return ts('myAccount.registration.email.codeExpired')
  }
})

const isHelpTextRed = computed(() => {
  return !props.isCartPage && !codeValidity.value.active
})

const timeLeftLabel = computed(() =>
  codeRequest.value.timeLeft > 0 ? `(${codeRequest.value.timeLeft})` : ''
)
</script>

<template>
  <div class="gap-sm flex flex-col">
    
<FormKitLazyProvider config-file="true">
<template v-if="!isCheckoutPage && !isCartPage">
      <span class="text-light-6">{{
        $ts('myAccount.registration.email.message')
      }}</span>
    </template>
    <div>
      <div>
        <div class="gap-xs flex flex-col">
          <InputsTextFields
            id="loginRegistrationEmail"
            type="email"
            :label="$ts('myAccount.login.email')"
            name="email"
            autocomplete="email"
            :disabled="!isCartPage"
            :action-text="
              isThankYouPage ? '' : $ts('myAccount.registration.email.edit')
            "
            @valid="e => emit('valid', e)"
            @click-action-button="editEmailHandler"
          />
          <InputsTextFields
            v-if="shouldShowVerificationCodeField"
            id="verificationCode"
            :label="$ts('myAccount.registration.email.code')"
            name="verificationCode"
            show-remove-button
            :help="helpText"
            :action-text="$ts('myAccount.registration.email.sendAgain')"
            :is-help-text-red="isHelpTextRed"
            :is-action-text-disabled="codeRequest.active"
            :extra-action-text="timeLeftLabel"
            @click-action-button="sendCodeRequest"
          />
        </div>
      </div>
    </div>
</FormKitLazyProvider>

  </div>
</template>
